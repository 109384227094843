import { mergeProps } from 'solid-js';
import { cn } from '~/utils/classnames';

interface ArrowProps {
  direction?: 'left' | 'right';
  onClick?: () => void;
  disabled?: boolean;
}

export const Arrow = (props: ArrowProps) => {
  const mergedProps = mergeProps({ direction: 'left' }, props);

  const onClick = () => {
    if (mergedProps.disabled) return;
    mergedProps.onClick?.();
  };

  return (
    <div
      onClick={onClick}
      class={cn(
        'cursor-pointer rounded-full border px-5 py-3 hover:bg-gray-100',
        mergedProps.direction === 'right' ? 'rotate-180' : '',
        props.disabled ? 'cursor-not-allowed opacity-50' : ''
      )}>
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.8996 18.1127C15.1728 17.8448 15.1977 17.4257 14.9741 17.1303L14.8996 17.0457L8.7327 11.0003L14.8996 4.95493C15.1728 4.68708 15.1977 4.26794 14.9741 3.97259L14.8996 3.88797C14.6264 3.62012 14.1988 3.59577 13.8975 3.81492L13.8112 3.88797L7.10041 10.4668C6.82719 10.7347 6.80235 11.1538 7.02589 11.4492L7.10041 11.5338L13.8112 18.1127C14.1118 18.4073 14.599 18.4073 14.8996 18.1127Z"
          fill="#333333"
        />
      </svg>
    </div>
  );
};
