import { LIKE_STORAGE_KEY } from '~/assets/strings';
import { UnitParams, PaginUnit, TenantHydratedUnitListingDto } from '~/types/Unit';
import { urlWithQuery } from '~/utils/url';
import { Gateway } from './gateway';
import { companyIdAttachingNetworkManager } from './network/companyIdAttachingNetworkManager';
import { MagicRequest, RequestMethod } from './network/magicRequest';

const COMPANY_UNITS_LISTING_URL = '/api/unit-listings';

class CompanyUnitListingGateway extends Gateway {
  public async getCompanyUnitsListing(params: UnitParams): Promise<PaginUnit> {
    const url = urlWithQuery(COMPANY_UNITS_LISTING_URL, params as any);

    const request: MagicRequest = {
      url,
      method: RequestMethod.GET,
      headers: this.createCommonHeaders(),
    };
    const response: Response = await this.sendRequest(request);
    const json: PaginUnit = await response.json();
    return json;
  }

  public async setLiked(unitId: string, isLiked: boolean) {
    const data = localStorage.getItem(LIKE_STORAGE_KEY);
    let likedUnit = data ? JSON.parse(data) : [];
    if (!isLiked) {
      likedUnit.push(unitId);
    } else {
      likedUnit = likedUnit.filter((id: string) => id !== unitId);
    }
    localStorage.setItem(LIKE_STORAGE_KEY, JSON.stringify(likedUnit));
  }

  public async getUnitListingDetail(unitId: string): Promise<TenantHydratedUnitListingDto> {
    const url = COMPANY_UNITS_LISTING_URL + `/${unitId}`;
    const request: MagicRequest = {
      url,
      method: RequestMethod.GET,
      headers: this.createCommonHeaders(),
    };
    const response: Response = await this.sendRequest(request);
    const json: TenantHydratedUnitListingDto = await response.json();
    return json;
  }
}

export const companyUnitListingGateway = new CompanyUnitListingGateway(companyIdAttachingNetworkManager);
