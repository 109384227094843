import { useNavigate, A } from '@solidjs/router';
import { Show, For } from 'solid-js';
import IconBack from '~/assets/icons/backArrow.svg';
import { BACK } from '~/assets/strings';
import { useLocalization } from '~/hooks/useLocalization';

interface AreaBreadcrumbProps {
  showBack: boolean;
  data: {
    name: string;
    path?: string;
  }[];
}

export const AreaBreadcrumb = (props: AreaBreadcrumbProps) => {
  const navigate = useNavigate();
  const { t } = useLocalization();
  return (
    <div class="flex w-max-container-width items-center ">
      <Show when={props.showBack}>
        <>
          <div class="flex cursor-pointer items-center gap-2" onClick={() => navigate(-1)}>
            <img src={IconBack} alt="back" />
            {t(BACK)}
          </div>
          <div class="mx-[30px] h-8 w-[2px] bg-border-level01" />
        </>
      </Show>

      <div class="flex items-center gap-2">
        <For each={props.data}>
          {(item, index) => (
            <div class="flex items-center gap-2">
              <Show when={item.path} fallback={<span>{item.name}</span>}>
                <A href={item.path!} class="text-text-level03">
                  {item.name}
                </A>
              </Show>
              <Show when={index() < props.data.length - 1}>
                <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1.46226 9.45947L0.543017 8.54023L4.0834 4.99985L0.543017 1.45947L1.46226 0.540234L5.92188 4.99985L1.46226 9.45947Z"
                    fill="#8D9BBD"
                  />
                </svg>
              </Show>
            </div>
          )}
        </For>
      </div>
    </div>
  );
};
